// src/utils/loader.js
let loaderVisible = false;

export const showLoader = () => {
  if (typeof document !== "undefined") {
    document.getElementById('loader-wrapper').style.display = 'block';
    loaderVisible = true;
  }
};

export const hideLoader = () => {
  if (typeof document !== "undefined") {
    setTimeout(() => {
      document.getElementById('loader-wrapper').style.display = 'none';
      loaderVisible = false;
    }, 1000);
  }
};
