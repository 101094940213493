// gatsby-browser.js
import React from 'react';
import { showLoader, hideLoader } from './src/utils/loader';
import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
import './src/assets/css/index.scss'


// Handle route changes to show and hide the loader
export const onPreRouteUpdate = () => {
    showLoader();
  };
  
  export const onRouteUpdate = () => {
    hideLoader();
  };

